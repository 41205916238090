import React, { Suspense, Fragment } from "react";
import loadable from "@loadable/component";
// import Header from "../components/Header";
// import Slider from "../components/Slider/home-one";
// import About from "../components/About/home-one";
// import Services from "../components/Services";
// import FromBlog from "../components/FromBlog";
// import HomeTabs from "../components/Tabs";
// import Footer from "../components/Footer";
// import Testimonials from "../components/Testimonials/home-one";
// import MobileMenu from "../components/MobileMenu";
// import Trichart from "../components/Trichart";
// import Feelbump from "../components/Feelbump";
// import Info from "../components/Info";
// import Schedule from "../components/Schedule";
// import SEO from "../components/SEO";
// import HomeSEO from "../helpers/SEO/Home";

// const Header = React.lazy(() => import("../components/Header"));
// const SEO = React.lazy(() => import("../components/SEO"));
// const Slider = React.lazy(() => import("../components/Slider/home-one"));
// const About = React.lazy(() => import("../components/About/home-one"));
// const Services = React.lazy(() => import("../components/Services"));
// const HomeTabs = React.lazy(() => import("../components/Tabs"));
// const Trichart = React.lazy(() => import("../components/Trichart"));
// const HomeSEO = React.lazy(() => import("../helpers/SEO/Home"));
// const Feelbump = React.lazy(() => import("../components/Feelbump"));
// const Testimonials = React.lazy(() =>
//   import("../components/Testimonials/home-one")
// );
// const FromBlog = React.lazy(() => import("../components/FromBlog"));
// const Info = React.lazy(() => import("../components/Info"));
// const Schedule = React.lazy(() => import("../components/Schedule"));
// const Footer = React.lazy(() => import("../components/Footer"));
// const MobileMenu = React.lazy(() => import("../components/MobileMenu"));
const Header = loadable(() => import("../components/Header"));
const SEO = loadable(() => import("../components/SEO"));
const Slider = loadable(() => import("../components/Slider/home-one/Slider"));
const About = loadable(() => import("../components/About/home-one"));
const Services = loadable(() => import("../components/Services"));
const HomeTabs = loadable(() => import("../components/Tabs"));
const Trichart = loadable(() => import("../components/Trichart"));
const HomeSEO = loadable(() => import("../helpers/SEO/Home"));
const Feelbump = loadable(() => import("../components/Feelbump"));
const Testimonials = loadable(() =>
  import("../components/Testimonials/home-one")
);
const FromBlog = loadable(() => import("../components/FromBlog"));
const Info = loadable(() => import("../components/Info"));
const Schedule = loadable(() => import("../components/Schedule"));
const Footer = loadable(() => import("../components/Footer"));
const MobileMenu = loadable(() => import("../components/MobileMenu"));

const Home = () => {
  return (
    // <Suspense fallback={<div>..</div>}>
    <Fragment>
      {/* <Header />
      <SEO />
      <Slider />
      <About />
      <Services classes="sm-top-wt" />
      <HomeTabs />
      <Trichart />
      <HomeSEO />
      <Feelbump />
      <Testimonials />
      <FromBlog />
      <Info />
      <Schedule />
      <Footer />
      <MobileMenu /> */}
      <Header />
      <SEO />
      <Slider />
      <About />
      <Services classes="sm-top-wt" />
      <HomeTabs />
      <Trichart />
      <HomeSEO />
      <Feelbump />
      <Testimonials />
      <FromBlog />
      <Info />
      {/* <Schedule /> */}
      <Footer />
      <MobileMenu />
    </Fragment>
    // </Suspense>
  );
};

export default Home;
